@import '../../style/App.scss';
#contact-mini {
    padding: 10px 2px;
    padding-bottom: 0px;
}

.small__button1 {
    button {
        background-color: $main-color;
        color: white;
        border: 2px solid $main-color;
        padding: 15px 40px;
        cursor: pointer;
        font-size: 1.3rem;
        font-weight: 800;
        margin: 5px 0 30px 0;
        outline: none;
        border-radius: 3px;
    }
    button:hover {
        border: 2px solid $main-color;
        background-color: transparent;
        color: $main-color;
    }
}

input {
    background-color: $light-color;
    width: 100%;
    max-width: 570px;
    height: 45px;
    border: 0px;
    padding: 0 15px;
    font-size: 12px;
    border: 2px solid $light-color;
    outline: none;
    margin: 5px 0;
}

input:active,
input:focus {
    border: 2px solid $main-color;
}

::placeholder {
    color: $black-color;
    font-size: 12px;
}

textarea {
    background-color: $light-color;
    width: 100%;
    max-width: 570px;
    border: 0px;
    padding: 15px;
    font-size: 12px;
    border: 2px solid $light-color;
    outline: none;
    margin: 5px 0;
}

textarea:active,
textarea:focus {
    border: 2px solid $main-color;
}

::placeholder {
    color: $black-color;
    font-size: 12px;
}

.sending-btn {
    width: 170px;
    background-color: $main-color;
    border: 1px solid $white-color;
    cursor: pointer;
    min-height: 52px;
    padding: 7px 0;
    margin: 0px;
    border-radius: 5px;
    div {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        .sbl-circ {
            color: $black-color;
            position: relative;
            display: inline-block;
            border: 5px solid;
            border-radius: 50%;
            border-top-color: transparent;
            animation: rotate 1s linear infinite;
        }
        @keyframes rotate {
            0% {
                transform: rotate(0);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}

.c-img {
    max-width: 100%
}

@media screen and (max-width: 990px) {
    #contact {
        text-align: center;
    }
    .sending-btn {
        margin: 30px auto;
    }
    c-img {
        max-width: 100%;
    }
}