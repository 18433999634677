@import '../../style/App.scss';
.footer {
    padding-top: 30px;
    h3 {
        font-weight: 500;
    }
    li,
    a {
        color: $white-color;
        font-size: 1.3rem;
        margin-top: 6px;
        margin-bottom: 6px;
        cursor: pointer
    }
    a:hover {
        color: $main-color;
    }
    p {
        color: $white-color;
        font-size: 1.2rem;
        margin-left: 30px;
    }
    background-color: #28140e;
    width: 100%;
    min-height: 180px;
    .footer-box {
        min-height: 100px;
        display: flex;
        align-items: center;
        p {
            color: $white-color;
            font-size: 1.2rem;
            margin-left: 30px;
        }
    }
    .flexcenter {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .back-to-top {
        float: right;
        cursor: pointer;
        img {
            margin-left: 10px;
        }
    }
    .back-to-top:hover {
        p {
            color: $main-color;
        }
    }
}

@media screen and (max-width: 580px) {
    .footer {
        .footer-box {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img {
                padding-bottom: 30px;
            }
        }
        .back-to-top {
            display: flex;
            justify-content: center;
            align-items: center;
            float: none;
            flex-direction: row;
            img {
                padding-bottom: 0px;
            }
        }
    }
}