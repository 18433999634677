@import '../../style/App.scss';

.team__box {
  max-width: 370px;
  height: 420px;
  margin: 0 auto;
  margin-top: 60px;
  position: relative;
  
  img {
    width: 100%;
    height: 100%;
  }

  .team__box-info {
    background-color: $main-color;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
    width: 80%;
    padding: 5px 20px;

    p {
      color: $black-color;
    }
    p:nth-child(2) {
      color: $white-color;
    }
  }
}