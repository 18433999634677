@import '../../style/App.scss';
.hero {
    position: relative;
    background-image: url('../../assets/hero/hero.jpg');
    background-size: cover;
    color: white;
    height: 100vh;
    padding-top: 180px;
    width: 100%;
    padding-bottom: 60px;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #232323b8; // This is a light dark overlay
        z-index: 1;
    }
    .hero-info {
        position: relative;
        z-index: 2;
        padding-top: 50px;
        p {
            padding-top: 20px;
            padding-bottom: 50px;
        }
    }
    .hero-image {
        position: relative;
        z-index: 2;
        max-width: 570px;
        height: auto;
        float: right;
    }
}

@media screen and (max-width: 1080px) {
    .hero {
        padding-top: 140px;
        .hero-info {
            padding-top: 0px;
        }
        .hero-image {
            float: none;
            margin: 0 auto;
            margin-top: 50px;
        }
    }
}

@media screen and (max-width: 990px) {
    .hero {
        .hero-info {
            text-align: center;
        }
        .font60 {
            font-size: 50px;
        }
    }
}